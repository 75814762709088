.stock-ticker-container {
  overflow: hidden;
  // overflow: auto;
  &:hover .stock-ticker {
    animation-play-state: paused;
  }
}

// @keyframes scrollTicker {
//   0% {
//     transform: translate(0, 0);
//   }
//   100% {
//     transform: translate(-90%, 0);
//   }
// }
@keyframes scrollTicker {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(0px - 50% - 20px));
  }
}

.stock-ticker {
  white-space: nowrap;
  animation: scrollTicker 20s linear infinite;
}

.stock-ticker-item {
  display: inline-block;
  margin-right: 20px;
}

.scroller {
  max-width: 100%;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  // -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  // mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;

  &:hover {
    animation-play-state: paused;
  }
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */
.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list {
  padding: 0.5rem 0;
}

.tag-list li {
  padding: 0.2rem 1rem;
  // padding: 1rem;
  // background: var(--clr-primary-400);
  border-radius: 0.5rem;
  // border: 1px solid rgba(128, 128, 128, 0.287);
  // background: rgb(153, 30, 224);
  // box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.133);
  // box-shadow: 0 0.5rem 1rem -0.25rem #8134af;
}
