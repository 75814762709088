.tablecell {
  &:not(:last-child) {
    border-right: 1px solid #e6e8f0 !important;
  }
}
.tablecell-dark {
  &:not(:last-child) {
    border-right: 1px solid rgb(117, 119, 126) !important;
  }
}

.text-center {
  text-align: center;
}
.center {
  display: grid;
  place-items: center;
}
